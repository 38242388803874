<template>
    <section class="nosotros-portada">
        <div class="container">

            <div class="row">
                <div class="col-lg-2"></div>

                <div class="col-lg-8 col-md-6 d-flex align-items-stretch">
                    <div class="icon-box" style="width: 33%;">
                        <img src="/assets/img/icons/home/icon-quienes-somos-home.svg" alt="">
                        <h5><a href="" @click="$router.push('/nosotros')">¿Quienes somos?</a></h5>
                    </div>
                    <div class="icon-box" style="width: 33%;">
                        <img src="/assets/img/icons/home/icon-como-trabajamos-home.svg" alt="">
                        <h5><a href="" @click="$router.push('/nosotros')">Cómo trabajamos?</a></h5>
                    </div>
                    <div class="icon-box" style="width: 33%;">
                        <img src="/assets/img/icons/home/icon-pregutas-home.svg" alt="">
                        <h5><a href="" @click="$router.push('/nosotros')">¿Preguntas frecuentes?</a></h5>
                    </div>
                </div>

                <div class="col-lg-2"></div>

            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: 'vNosotrosPortada'
}
</script>