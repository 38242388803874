<template>
    <section class="servicio section-bg">
        <div class="container">
            <div class="section-title">
                <div class="row">
                    <div class="col-md-8">
                        <h3 style="color: white;">Transparencia</h3>
                        <p style="color: white; text-align: justify">
                            Haz tu solicitud de Accesos a la Información Pública<br/>
                            Acceso a la Información Pública<br/>
                            <a style="color: white" href="https://www.contraloria.gov.py/index.php/transparencia-disposiciones-legales/file/26635-ley-5282-2014" target="_blank">Ley N° 5282/2014</a>
                        </p>
                    </div>
                    <div class="col-md-4">
                        <br>
                        <button class="btn-solicitar" @click="solicitarInformacion">Solicitar Información</button><br><br>
                        <button class="btn-ver-estado" @click="verSolicitudes">Ver todas la solicitudes</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'vTransparencia',
    methods: {
        solicitarInformacion() {
            window.open('https://informacionpublica.paraguay.gov.py/portal/#!/hacer_solicitud', '_blank');
        },
        verSolicitudes() {
            window.open('https://informacionpublica.paraguay.gov.py/portal/#!/buscar_informacion?ver_todas#busqueda', '_blank');
        }
    }
}
</script>

<style>
    .btn-solicitar {
        border: none;
        color: #fff;
        padding: 14px 102px;
        border-radius: 10px;
        background-color: #eb1515;
    }
    .btn-ver-estado {
        border: none;
        text-decoration: none;
        color: #fff;
        padding: 14px 92px;
        border-radius: 10px;
        background-color: #356AA0;
        border: 1px solid #fff;
    }
</style>