class JSONService {

    
    getDataOEE() {
        //return fetch('http://10.168.201.251:3000/data/mensajes.json').then(res => res.json()).then(d => d.data);
        // return fetch('http://localhost:8080/data/oee.json').then(res => res.json()).then(d => d.data);
        return fetch('https://denuncias.contraloria.gov.py/data/oee.json').then(res => res.json()).then(d => d.data); // Produccion Denuncias
        // return fetch('https://djbr2.contraloria.gov.py/data/oee.json').then(res => res.json()).then(d => d.data); // Desarrollo DJBR2
        
    }

    getDataMunicipio() {
        //return fetch('http://10.168.201.251:3000/data/mensajes.json').then(res => res.json()).then(d => d.data);
        // return fetch('http://localhost:8080/data/municipio.json').then(res => res.json()).then(d => d.data);
        return fetch('https://denuncias.contraloria.gov.py/data/municipio.json').then(res => res.json()).then(d => d.data); // Produccion Denuncias
        // return fetch('https://djbr2.contraloria.gov.py/data/municipio.json').then(res => res.json()).then(d => d.data); // Desarrollo DJBR2
    }

    getDataDepartamento() {
        // return fetch('http://10.168.201.251:3000/data/mensajes.json').then(res => res.json()).then(d => d.data);
        // return fetch('http://localhost:8080/data/departamento.json').then(res => res.json()).then(d => d.data);
        return fetch('https://denuncias.contraloria.gov.py/data/departamento.json').then(res => res.json()).then(d => d.data); // Produccion Denuncias
        // return fetch('https://djbr2.contraloria.gov.py/data/departamento.json').then(res => res.json()).then(d => d.data); // Desarrollo DJBR2
        
    }
}
export default new JSONService();