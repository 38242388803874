<template>
    <section class="servicio section-bg">
        <div class="container">
            <div class="section-title">
                <h3 style="color: white;">El servicio es gratuito y confidencial</h3>
                <p style="color: white">Denuncie aquí actos de corrupción o manejo indebido del Patrimonio Público en las Instituciones Públicas, y aquellas vinculadas al Estado.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'vServicio'
}
</script>