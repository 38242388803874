<template>
    <section id="qs" class="about-us row w-100 justify-content-center m-0 pt-xl-5 pt-3 pb-5">
        <div class="text-center text-responsive-about-us p-lg-5 p-4">
            <h2>DIRECCIÓN GENERAL DE INTEGRIDAD PÚBLICA Y TRANSPARENCIA</h2>
            <h4>DIRECCIÓN DE GESTIÓN ENTICORRUPCIÓN</h4>
        </div>
        <div class="col-xl-5 col-lg-6 general-management p-0">
            <img src="@/assets/img/nosotros/card-direccion-general.png" alt="">
        </div>
        <div class="col-xl-5 about-us-description ms-xl-5 my-lg-5 p-0">
            <h2 class="text-center m-0 pt-4">¿Quiénes somos?</h2>
            <div class="p-4 text-about-us">
                <p>La Contraloría General de la República, es un organismo de control de las actividades económicas y financieras del Estado, de los Departamentos y de las Municipalidades, en la forma determinada por la Constitución Nacional y la Ley N° 276/94. Goza de autonomía funcional y administrativa.</p>
                <p>De conformidad a la Resolución CGR N° 216/2022, la Dirección General de Integridad Pública y Transparencia es la unidad organizacional encargada de fomentar la transparencia, integridad, medidas preventivas, correctivas y demás mecanismos de lucha contra la corrupción. Asimismo, conforme a las normativas vigentes es la encargada de dar trámite y respuesta a las Denuncias Ciudadanas ingresadas a la Institución.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'vQuienesSomos',
}
</script>

<style>
.about-us .text-responsive-about-us {
	display: none;
	background-color: #fff;
}
.about-us .general-management {
	margin-right: -6.5%;
}
.about-us .about-us-description {
	color: #fff;
	border-radius: 8px;
	margin: 2rem 0rem 2rem 3rem;
	background-color: #356aa0;
	box-shadow: 0px 2px 48px #000 14;
}
.about-us .about-us-description .text-about-us {
	font-size: 17.5px;
}
</style>