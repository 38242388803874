<template>
    <v-header></v-header>
    <v-quienes-somos></v-quienes-somos>
    <v-como-trabajamos></v-como-trabajamos>
    <v-preguntas-frecuentes></v-preguntas-frecuentes>
    
    
    <v-footer></v-footer>
</template>

<script>
import vHeader from '@/components/vHeader.vue';
import vFooter from '@/components/vFooter.vue';
import vQuienesSomos from '@/components/nosotros/vQuienesSomos.vue';
import vComoTrabajamos from '@/components/nosotros/vComoTrabajamos.vue';
import vPreguntasFrecuentes from '@/components/nosotros/vPreguntasFrecuentes.vue';

export default {
    name: 'vNosotros',

    components: {
        vHeader,
        vFooter,
        vQuienesSomos,
        vComoTrabajamos,
        vPreguntasFrecuentes
    }
}
</script>