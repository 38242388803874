<template>
    <section class="formulario-denuncia">
        <div class="container">
            <div class="section-title">
                <h3 class="color-text">Denuncias</h3>
            </div>
            <Form @submit="crear">
                <div class="row">
                    <h5 class="color-text">
                        Podés realizar tu denuncia sobre irregularidades en:
                    </h5>
                </div>
                <br />
                <div class="pasos-form-denuncias">
                    <div class="container" @mouseout="botonDenuncia()" @mouseover="botonDenuncia()">
                        <!-- tipo de denuncia -->
                        <div class="row">
                            <div class="col-lg-2 col-md-6 mt-4 mt-md-0" @click="activeType('rendiciones')" >
                                <div :class="active_rendiciones ? 'active' : 'inactive'" style="height: 400px">
                                    <img
                                        src="assets/img/denuncias/img-rendiciones.png"
                                        style="height: 60px !important"
                                        alt="..."
                                    />
                                    <p class="card-text mt-2">
                                        -Rendiciones de cuentas en concepto de FONACIDE y Royalties (el uso de los mismos, o alguna irregularidad detectada en el Corte Administrativo, etc.).
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-6 mt-4 mt-md-0" @click="activeType('presentacion')">
                                <div :class="active_presentacion ? 'active' : 'inactive'" style="height: 400px">
                                    <img
                                        src="assets/img/denuncias/img-presentacion.png"
                                        style="height: 60px !important"
                                        alt="..."
                                    />
                                    <p class="card-text mt-2">
                                        Presentación o no de las Declaraciones Juradas, Doble Remuneración, Análisis de Correspondencia.
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-6 mt-4 mt-md-0" @click="activeType('licitaciones')">
                                <div :class="active_licitaciones ? 'active' : 'inactive'" style="height: 400px">
                                    <img
                                        src="assets/img/denuncias/img-licitacioness.svg"
                                        style="height: 60px !important"
                                        alt="..."
                                    />
                                    <p class="card-text mt-2">
                                        Licitaciones públicas (incumplimiento de contratos, irregularidad en el proceso de llamado de licitación pública, etc.)
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-6 mt-4 mt-md-0" @click="activeType('manejo')">
                                <div :class="active_manejo ? 'active' : 'inactive'" style="height: 400px">
                                    <img
                                        src="assets/img/denuncias/img-manejo-indebido.png"
                                        style="height: 60px !important"
                                        alt="..."
                                    />
                                    <p class="card-text mt-2">
                                        Manejo indebido del Bien Público (Instituciones Públicas, Gobernaciones, Municipalidades, etc.)
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-6 mt-4 mt-md-0" @click="activeType('funcionarios')">
                                <div :class="active_funcionarios ? 'active' : 'inactive'" style="height: 400px">
                                    <img
                                        src="assets/img/denuncias/Icon_awesome-users_B.png"
                                        style="height: 60px !important"
                                        alt="..."
                                    />
                                    <p class="card-text mt-2">Contra Funcionarios de la CGR</p>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-6 mt-4 mt-md-0" @click="activeType('otros')">
                                <div :class="active_otros ? 'active' : 'inactive'" style="height: 400px">
                                    <img
                                        src="assets/img/icons/denuncias/icon-otros.svg"
                                        style="height: 60px !important"
                                        alt="..."
                                        class="px-2 p-4"
                                    />
                                    <p class="card-text mt-2">Otros</p>
                                </div>
                            </div>
                        </div>
                        <!-- denuncia anonima si/no -->
                        <div class="row">
                            <div class="buttons my-5 row justify-content-lg-start justify-content-center">
                                <div class="col-lg-6 col-10 p-0 m-1">
                                    <div>
                                        <h5 class="text-lg-start text-center color-text">
                                            ¿Desea enviar su denuncia de forma anónima?
                                        </h5>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="btn-group d-flex mt-4" role="group" aria-label="Basic example">
                                            <button type="button" class="btn btn-primary w-20 p-3"
                                                :class="denuncia.denunciaAnonima ? 'active-btn-denuncia-yes' : 'btn-yes'"
                                                @click="tooggleShowForm(true)">SI</button>
                                            <button type="button" class="btn btn-outline-primary w-20 p-3"
                                                :class="denuncia.denunciaAnonima ? 'btn-no' : 'active-btn-denuncia-no'"
                                                @click="tooggleShowForm(false)">NO</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- denunciante -->
                            <div v-if="showForm">
                                <div class="row justify-content-between mb-3">
                                    <div class="p-0 col-lg-10 col-6">
                                        <h5 class="text-lg-start text-center color-text">
                                            Denunciante/s
                                        </h5>
                                    </div>
                                </div>
                                <div class="row data justify-content-between">
                                    <div class="table-wrapper p-0">
                                        <table class="table table-info-d mb-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="col-lg-1">Nro.</th>
                                                    <th scope="col" class="col-lg-2">Nombre y apellido</th>
                                                    <th scope="col" class="col-lg-2">Tipo de documento</th>
                                                    <th scope="col" class="col-lg-2">Nro. de documento</th>
                                                    <th scope="col" class="col-lg-2">Correo electronico</th>
                                                    <th scope="col" class="col-lg-2">Teléfono</th>
                                                    <th scope="col" class="col-lg-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="!carga_denunciantes">
                                                <tr v-for="(denunciantes, index) in arrayDenunciante" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ denunciantes.nombreCompleto }}</td>
                                                    <td>{{ denunciantes.tipoDocumento }}</td>
                                                    <td>{{ denunciantes.nroDocumento }}</td>
                                                    <td>{{ denunciantes.email }}</td>
                                                    <td>{{ denunciantes.telefono }}</td>
                                                    <td class="d-flex justify-content-end">
                                                        <img @click="eliminarDenunciante(index)" class="px-3" src="assets/icons/denuncias/Icon-feather-trash.svg" alt="trash" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <!-- <Field type="text" class="form-control form-control-sm" aria-label="nombreApellido" v-model="denunciantes.nombreCompleto" name="nombreApellido" :rules="isRequired" /> -->
                                                        <Field type="text" class="form-control form-control-sm" aria-label="nombreApellido" v-model="denunciantes.nombreCompleto" name="nombreApellido" />
                                                        <ErrorMessage name="nombreApellido" class="error-feedback" style="color: red; font-size: 14px" />
                                                    </td>
                                                    <td>
                                                        <select class="form-control form-control-sm" aria-label="Default select example" v-model="denunciantes.tipoDocumento">
                                                            <option selected value="">seleccione una opción</option>
                                                            <option value="CC">Cédula de identidad civil</option>
                                                            <option value="Pasaporte">Número de pasaporte</option>
                                                            <option value="Otros">Otros</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <!-- <Field type="text" class="form-control form-control-sm" aria-label="documento" v-model="denunciantes.nroDocumento" name="documento" :rules="isRequired" /> -->
                                                        <Field type="text" class="form-control form-control-sm" aria-label="documento" v-model="denunciantes.nroDocumento" name="documento" />
                                                        <ErrorMessage name="documento" class="error-feedback" style="color: red; font-size: 14px" />
                                                    </td>
                                                    <td>
                                                        <!-- <Field type="email" class="form-control form-control-sm" aria-label="email" v-model="denunciantes.email" name="email" :rules="validateEmail" /> -->
                                                        <Field type="email" class="form-control form-control-sm" aria-label="email" v-model="denunciantes.email" name="email" />
                                                        <ErrorMessage name="email" class="error-feedback" style="color: red; font-size: 14px" />
                                                    </td>
                                                    <td>
                                                        <!-- <Field type="text" class="form-control form-control-sm" aria-label="tel" v-model="denunciantes.telefono" name="tel" :rules="isRequired" /> -->
                                                        <Field type="text" class="form-control form-control-sm" aria-label="tel" v-model="denunciantes.telefono" name="tel" />
                                                        <ErrorMessage name="tel" class="error-feedback" style="color: red; font-size: 14px" />
                                                    </td>
                                                    <td class="px-4">
                                                        <div class="text-end">
                                                            <button class="btn btn-sm btn-primary" type="submit" @click="nuevoDenunciante()">
                                                                Guardar
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- denuncia ante otra institucion -->
                        <div class="row">
                            <div class="buttons my-5 row justify-content-lg-start justify-content-center">
                                <div class="col-lg-6 col-4 p-0 m-1">
                                    <div>
                                        <h5 class="color-text">
                                            ¿Fue presentada una denuncia ante otra institución?
                                        </h5>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="btn-group d-flex mt-4" role="group" aria-label="Basic example">
                                            <button type="button" class="btn btn-outline-primary w-20 p-3" :class="denuncia.denunciaOtraInstitucion ? 'active-btn-denuncia-yes' : 'btn-yes'" @click="activeChange_other(true)">
                                                SI
                                            </button>
                                            <button type="button" class="btn btn-primary w-20 p-3" :class="denuncia.denunciaOtraInstitucion ? 'btn-no' : 'active-btn-denuncia-no'" @click="activeChange_other(false)">
                                                NO
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="col-lg-6 col-4 p-0 m-1" v-if="denuncia.denunciaOtraInstitucion">
                                    <h5 class="text-lg-start text-center color-text">
                                        Nombre de institución
                                        <div class="tool-one"></div>
                                    </h5>
                                    <div class="col-lg-12">
                                        <Select2 class="form-control form-control-sm" v-model="denuncia.otraInstitucion" :options="instituciones" :settings="{ text: instituciones }" @select="seleccionOtraOEE($event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- informacion denunciado -->
                        <div class="row">
                            <div>
                                <div class="row justify-content-between mb-3">
                                    <div class="p-0 col-lg-10 col-6">
                                        <h4 class="ms-1 mb-4 color-text">
                                            Información de la denuncia
                                        </h4>
                                    </div>
                                    <div class="p-0 col-lg-10 col-6">
                                        <h5 class="ms-1 mb-4 color-text">
                                            Denunciado/s (opcional)
                                        </h5>
                                    </div>
                                </div>
                                <div class="row data justify-content-between">
                                    <div class="table-wrapper p-0">
                                        <table class="table table-info-d mb-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="col-lg-1">Nro.</th>
                                                    <th scope="col" class="col-lg-2">Nombre y apellido</th>
                                                    <th scope="col" class="col-lg-2">Tipo de documento</th>
                                                    <th scope="col" class="col-lg-2">Nro. de documento</th>
                                                    <th scope="col" class="col-lg-2">Funcionario</th>
                                                    <th scope="col" class="col-lg-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(denunciado, index) in arrayDenunciado" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ denunciado.nombreCompleto }}</td>
                                                    <td>{{ denunciado.tipoDocumento }}</td>
                                                    <td>{{ denunciado.nroDocumento }}</td>
                                                    <td v-if="denunciado.esFuncionario == true">SI</td>
                                                    <td v-if="denunciado.esFuncionario == false">NO</td>
                                                    <td class="d-flex justify-content-end">
                                                        <img @click="eliminarDenunciado(index)" class="px-3" src="assets/icons/denuncias/Icon-feather-trash.svg" alt="trash" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" aria-label="nombreApellido" v-model="denunciados.nombreCompleto" />
                                                    </td>
                                                    <td>
                                                        <select class="form-control form-control-sm" aria-label="Default select example" v-model="denunciados.tipoDocumento">
                                                            <option selected value="">seleccione una opción</option>
                                                            <option value="CC">Cédula de identidad civil</option>
                                                            <option value="Pasaporte">Número de pasaporte</option>
                                                            <option value="Otros">Otros</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" aria-label="documento" v-model="denunciados.nroDocumento" />
                                                    </td>
                                                    <td>
                                                        <select class="form-control form-control-sm" @change="funcionarioBoolean($event)">
                                                            <option selected value="">seleccione una opción</option>
                                                            <option value="si">Si</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </td>
                                                    <td class="px-4">
                                                        <div class="text-end">
                                                            <button class="btn btn-sm btn-primary" type="submit" @click="nuevoDenunciado(), limpiarDenunciados()">
                                                                Guardar
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- datoss varios de la denuncia -->
                        <div class="row">
                            <div class="col-lg-4 col-12 px-1 mb-md-4 mb-3">
                                <div>
                                    <h5 class="ms-1 color-text">Fecha en que ocurrieron los hechos</h5>
                                </div>
                                <input type="date" class="form-control form-control-sm p-2" v-model="denuncia.fechaDeLosHechos" />
                            </div>
                            <div class="col-lg-4 col-12 px-1 mb-md-4 mb-3">
                                <div>
                                    <h5 class="ms-1 color-text">Institución Denunciada</h5>
                                </div>
                                <div class="input-group">
                                    <Select2 class="form-control form-control-sm" v-model="denuncia.institucionDenunciada" :options="instituciones" :settings="{ text: instituciones }" @select="seleccionOtraOEE($event)" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-12 px-1 mb-md-4 mb-3" v-if="isOtraInstitucion">
                                <div>
                                    <h5 class="ms-1 color-text">Otra institución</h5>
                                </div>
                                <div class="input-group">
                                    <input class="form-control form-control-sm" type="text" v-model="denuncia.otraInstitucionDenunciada" />
                                </div>
                            </div>
                            <div class="row">
                                <!-- departamento -->
                                <div class="col-md-4 col-12 px-1 mb-md-4 mb-3">
                                    <div>
                                        <h5 class="ms-1 color-text">Departamento</h5>
                                    </div>
                                    <div class="input-group">
                                        <Select2 class="form-control form-control-sm" v-model="denuncia.departamento" :options="deptos" :settings="{ value: deptos }" @change="actualizarMun" />
                                    </div>
                                </div>
                                <!-- municipio -->
                                <div class="col-md-4 col-12 px-1 mb-md-4 mb-3">
                                    <div>
                                        <h5 class="ms-1 color-text">Municipio</h5>
                                    </div>
                                    <div class="input-group">
                                        <Select2 class="form-control form-control-sm" v-model="denuncia.ciudad" :options="municipiosFiltrados" :settings="{ text: municipiosFiltrados }" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- descripcion de los hechos -->
                        <div class="row">
                            <div class="mb-3 p-0 input-text-tarea">
                                <div>
                                    <h5 class="ms-1 mt-5 color-text">Descripción de los Hechos y/o Circunstancias</h5>
                                </div>
                                <textarea
                                    maxlength="2000"
                                    class="form-control form-control-sm"
                                    id=""
                                    v-model="denuncia.descripcion"
                                    placeholder="Logre proveer todos los datos que consideren oportunos, y hagan lugar a la presente denuncia; con la mayor precisión posible, a fin de que haga efectivo el análisis del mismo."
                                    rows="6"
                                ></textarea>
                                <div class="text-end">
                                    <p>Max. caracteres: {{ remainingChars }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- seleccionar  archivo adjunto-->
                        <div class="row">
                            <div v-show="false">
                                <label for="file-upload">Seleccionar archivo:</label>
                                <input ref="fileSelector" type="file" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .mp3, .mp4" id="file-upload" @change="onFileChange" />
                            </div>

                            <div class="tabla mt-5">
                                <div class="p-0 row justify-content-between mx-1">
                                    <div class="col-md-9 col-7 p-0">
                                        <h5 class="ms-0 mb-md-4 mb-3 info-denuncia__title color-text">
                                            Información de la denuncia<span class="ms-3">(Sólo se aceptan archivos JPG, PNG, PDF, DOC, DOCX, XLS, XLSX y MP3, hasta 4 archivos y hasta 50Mb. Si cuentas con más de 4 archivos, por favor, comprímelos en un único archivo.)</span>
                                            <!-- Información de la denuncia<span class="ms-3">(Sólo se aceptan archivos PDF todos hasta 50Mb)</span> -->
                                        </h5>
                                    </div>
                                    <div class="col-xl-2 col-md-3 col-5 p-0 mb-3 text-end">
                                        <button type="button" class="btn btn-outline-primary p-3 text-center btn-archivo" @click="btnFileSelector()">
                                            Subir archivo
                                            <img class="ps-md-3 ps-1 pb-1 px-0" src="assets/img/icons/denuncias/icon-subir-archivo.svg" alt="" />
                                        </button>
                                    </div>
                                </div>

                                <div class="table-wrapper px-1">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nombre del archivo</th>
                                                <th scope="col">Tipo de archivo</th>
                                                <th scope="col">Tamaño</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="archivos in arrayFile" :key="archivos">
                                                <td>{{ archivos.selectArchivo.name }}</td>
                                                <td>{{ archivos.selectArchivo.type }}</td>
                                                <td>{{ (archivos.selectArchivo.size / 1024 ** 2).toFixed(3) }}  MB</td>
                                                <td class="d-flex justify-content-end">
                                                    <img @click="eliminarAdjunto(index)" class="px-3" src="assets/icons/denuncias/Icon-feather-trash.svg" alt="trash" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <!-- boton guardar -->
                        <div class="btn-send text-center mt-5">
                            <button type="submit" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" v-if="vacio">
                                <!-- @click="crear()"  -->
                                Enviar denuncia
                            </button>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog modal-xl modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">

                                        <vue3-simple-html2pdf id="vue3SimpleHtml2pdf" :options="pdfOptions" :filename="exportFilename">
                                            <div class="page">
                                                <div class="page-header" style="text-align: center">
                                                    <!-- <tab-header></tab-header> -->
                                                    <h4 class="mb-12 titulo-modal color-text">Datos de la denuncia</h4>
                                                    <p class="text-start ps-2">
                                                        Antes de enviar tu denuncia, verifica que los datos sean correctos
                                                    </p>
                                                </div>
                                                <div class="page-body">

                                                    <br />

                                                    <div class="card shadow card-outline">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <!-- tipo de denuncia -->
                                                                <div class="col-md-12">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-3">
                                                                            <label for="tipoDenuncia" class="color-text form-label">Denuncia sobre:&nbsp;</label>
                                                                            <label for="tipoDenuncia" class="form-label">{{ denuncia.tipoDenuncia }}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- otra institución en donde se presentó al denuncia -->
                                                                <div class="col-md-12" v-if="denuncia.otraInstitucion !== null && denuncia.otraInstitucion !== ''">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-3">
                                                                            <label for="otraInstitucionDenunciaPresentada" class="color-text form-label">Denuncia sobre:&nbsp;</label>
                                                                            <label for="otraInstitucionDenunciaPresentada" class="form-label">{{ denuncia.otraInstitucion }}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- fecha de la denuncia -->
                                                                <div class="col-md-12" v-if="denuncia.fechaDeLosHechos !== null && denuncia.fechaDeLosHechos !== ''">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-3">
                                                                            <label for="fechaDenuncia" class="color-text form-label">Fecha Denuncia:&nbsp;</label>
                                                                            <label for="fechaDenuncia" class="form-label">{{ denuncia.fechaDeLosHechos }}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- institución denunciada -->
                                                                <div class="col-md-12" v-if="denuncia.institucionDenunciada !== null && denuncia.institucionDenunciada !== ''">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-3" v-if="!this.isOtraInstitucion">
                                                                            <label for="institucionDenunciada" class="color-text form-label">Institución Denunciada:&nbsp;</label>
                                                                            <label for="institucionDenunciada" class="form-label">{{ denuncia.institucionDenunciada }}</label>
                                                                        </div>
                                                                        <!-- otra institucion denunciada -->
                                                                        <div class="mb-3" v-if="this.isOtraInstitucion">
                                                                            <label for="otraInstitucionDenuncia" class="color-text form-label">Institución Denunciada:&nbsp;</label>
                                                                            <label for="institucionDenunciada" class="form-label">{{ denuncia.otraInstitucionDenunciada }}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- departamento -->
                                                                <div class="col-md-12" v-if="denuncia.departamento !== null && denuncia.departamento !== ''">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-3">
                                                                            <label for="institucionDenunciada" class="color-text form-label">Departamento:&nbsp;</label>
                                                                            <label for="institucionDenunciada" class="form-label">{{ denuncia.departamento }}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- ciudad -->
                                                                <div class="col-md-12" v-if="denuncia.ciudad !== null && denuncia.ciudad !== ''">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-3">
                                                                            <label for="institucionDenunciada" class="color-text form-label">Municipio:&nbsp;</label>
                                                                            <label for="institucionDenunciada">{{ denuncia.ciudad }}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- descripcion de los hechos -->
                                                                <div class="col-md-12" v-if="denuncia.descripcion !== null && denuncia.descripcion !== ''">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-3">
                                                                            <label for="denunciaDescripcion" class="color-text form-label">Descripción de los Hechos:&nbsp;</label>
                                                                            <label for="denunciaDescripcion" class="col-md-12">{{ denuncia.descripcion }}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- denunciantes -->
                                                                <div class="col-md-12" v-if="denuncia.denunciaAnonima != true">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-5">
                                                                            <h5 class="color-text">Denunciantes</h5>
                                                                            <div class="table-responsive">
                                                                                <table class="table table-info-d mb-5">
                                                                                    <thead class="color-text">
                                                                                        <tr>
                                                                                            <th scope="col" class="col-lg-1">Nro.</th>
                                                                                            <th scope="col" class="col-lg-2">Nombre y apellido</th>
                                                                                            <th scope="col" class="col-lg-2">Tipo de documento</th>
                                                                                            <th scope="col" class="col-lg-2">Nro. de documento</th>
                                                                                            <th scope="col" class="col-lg-2">Correo electronico</th>
                                                                                            <th scope="col" class="col-lg-2">Teléfono</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(denunciantes, index) in denuncia.denunciantes" :key="index">
                                                                                            <td>{{ index + 1 }}</td>
                                                                                            <td>{{ denunciantes.nombreCompleto }}</td>
                                                                                            <td>{{ denunciantes.tipoDocumento }}</td>
                                                                                            <td>{{ denunciantes.nroDocumento }}</td>
                                                                                            <td>{{ denunciantes.email }}</td>
                                                                                            <td>{{ denunciantes.telefono }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- denunciados -->
                                                                <div class="col-md-12" v-if="denuncia.denunciados.length > 0">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-5">
                                                                            <h5 class="color-text">Denunciados</h5>
                                                                            <div class="table-responsive">
                                                                                <table class="table table-vcenter card-table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" class="col-lg-1 color-text">Nro.</th>
                                                                                            <th scope="col" class="col-lg-2 color-text">Nombre y apellido</th>
                                                                                            <th scope="col" class="col-lg-2 color-text">Tipo de documento</th>
                                                                                            <th scope="col" class="col-lg-2 color-text">Nro. de documento</th>
                                                                                            <th scope="col" class="col-lg-2 color-text">Funcionario</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(denunciado, index) in denuncia.denunciados" :key="index">
                                                                                            <td>{{ index + 1 }}</td>
                                                                                            <td>{{ denunciado.nombreCompleto }}</td>
                                                                                            <td>{{ denunciado.tipoDocumento }}</td>
                                                                                            <td>{{ denunciado.nroDocumento }}</td>
                                                                                            <td>{{ denunciado.esFuncionario }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <!-- archivos adjuntos -->
                                                                <div class="col-md-12" v-if="denuncia.archivos.length > 0">
                                                                    <div class="form-group" style="text-align: left">
                                                                        <div class="mb-5">
                                                                            <h5 class="color-text">Denunciados</h5>
                                                                            <div class="table-responsive">
                                                                                <table class="table table-vcenter card-table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" class="col-lg-4 color-text">Nombre</th>
                                                                                            <th scope="col" class="col-lg-8 color-text">Archivos</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(archivo, index) in denuncia.archivos" :key="archivo">
                                                                                            <td>archivoAdjunto{{ index + 2 }}</td>
                                                                                            <td>{{ archivo.selectArchivo.name }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </vue3-simple-html2pdf>

                                    </div>

                                    <div class="modal-footer justify-content-center pb-4 p-0">

                                        <div class="btn-send text-center mt-5">
                                            <button type="button" class="btn btn-primary" @click="convertirYEnviar()" data-bs-dismiss="modal">
                                                Enviar denuncia
                                            </button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Form>
            <div v-if="loading" class="loader">
                <h5>Enviando Formulario..<br />Por favor espere..</h5>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import JSONService from "@/services/JSON.service";
import Select2 from "vue3-select2-component";
import html2pdf from "html2pdf.js";
import xml2js from 'xml2js';
// import api from '@/services/api.js';
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
    name: "vFormDenuncias",
    components: { Select2, Form, Field, ErrorMessage },
    data() {
        return {
            inputText: "",
            maxLength: 2000,
            showForm: false,
            active: true,
            active_rendiciones: true,
            active_presentacion: false,
            active_licitaciones: false,
            active_manejo: false,
            active_funcionarios: false,
            active_otros: false,
            funcionario: "",
            // denuncia_response: Object,
            carga_comprobante: false,
            copiar_comprobante: "",
            copiado: false,
            vacio: false,
            municipio: null,
            archivos: [],
            isOtraInstitucion: false,
            // selectArchivo: null as File | null,
            maximoPermitido: 50000000,

            docBase64: '',

            instituciones: [],
            deptos: [],
                //  ['Capital', 'Concepción', 'San Pedro', 'Cordillera', 'Guairá', 'Caaguazú', 'Caazapá', 'Itapúa', 'Misiones', 'Paraguarí', 'Alto Paraná', 'Central', 'Ñeembucú', 'Amambay', 'Canindeyú', 'Presidente Hayes', 'Alto Paraguay', 'Boquerón'], // as string[],
            mun: [],
            //  {
            //     'Capital': ['Asunción'],
            //     'Concepción': ['Arroyito', "Azote'Y", 'Belén', 'Concepción', 'Horqueta', 'Itacua', 'Loreto', 'Paso Barreto', 'Paso Horqueta, San Alfredo', 'San Carlos Del Apa, San Lázaro', 'Sargento Jose Felix Lopez', 'Yby Yaú'],
            //     'San Pedro': ['25 De Diciembre', 'Antequera', 'Capiibary', 'Choré', 'Gral Elizardo Aquino', 'Gral. I. Resquín', 'Guajayvi', 'Itacurubí Del Rosario ', 'Liberacion', 'Lima', 'Nueva Germania', 'San Estanislao', 'San Jose Del Rosario', 'San Pablo', 'San Pedro De Ycuamandiyú', 'San Vicente Pancholo', 'Santa Rosa Del Aguaray', 'Unión', 'Villa Del Rosario', 'Yataity Del Norte', 'Yrybukuá'],
            //     'Cordillera': ['3 De Febrero', 'Altos', 'Arroyos Y Esteros', 'Atyra', 'Caacupé', 'Curuguaty', 'Emboscada', 'Eusebio Ayala', 'Isla Pucú', 'Itacurubí De La Cordillera', 'Juan De Mena', 'Loma Grande', 'Mbocayaty Del Yhaguy', 'Nueva Colombia', 'Piribebuy', 'Primero De Marzo', 'San Bernardino', 'San JosÉ Obrero', 'Santa Elena', 'Tacuati', 'TobatÍ', 'Valenzuela'],
            //     'Guairá': ['Borja', 'Cap. M. JosÉ Troche', 'Colonia Independencia', 'Coronel Martínez', 'Dr. Bottrell', 'Dr. Moisés Bertoni', 'Félix Perez Cardozo', 'Gral. Eugenio A. Garay', 'Itapé', 'Iturbe', 'José Fassardi', 'Mbocayaty Del Guairé', 'Natalicio Talavera', 'Ñumí', 'Paso Yobai', 'San Salvador', 'Tebicuary', 'Villarrica', 'Yataity Del Guairá'],
            //     'Caaguazú': ['Caaguazú', 'Carayaó', 'Coronel Oviedo', 'Dr. Cecilio Baez', 'Dr. J. Eulogio Estigarribia', 'Dr. Juan Manuel Frutos', 'José Domingo Ocampos', 'La Pastora', 'Mcal. F. Solano López', 'Nueva Londres', 'Nueva Toledo', 'R.I.3 Corrales', 'Raúl Arsenio Oviedo', 'Repatriación', 'San Joaquín', 'San José De Los Arroyos', 'Santa Rosa Del Mbutuy', 'Simon Bolivar', 'Tembiaporậ', 'Vaquería', 'Yhú'],
            //     'Caazapá': ['3 De Mayo', 'Abaí', 'Buena Vista', 'Caazapá', 'General H. MorÍNigo', 'Maciel', 'Tavaí', 'Yegros', 'Yuty'],
            //     'Itapúa': ['Alto Vera', 'Bella Vista', 'Cambyretá', 'Capitán Meza', 'Capitan Miranda', 'Carlos A.López', 'Carmen Del Paraná', 'Coronel Bogado', 'Edelira', 'Encarnación', 'Fram', 'General Artigas', 'General Delgado', 'Hohenau', 'Itapua Poty', 'Jesús', 'José L. Oviedo', 'La Paz', 'Mayor Julio D. Otaño', 'Natalio', 'Nueva Alborada', 'Obligado', 'Pirapó', 'San Cosme Y Damián', 'San Juan Del Paraná', 'San Pedro Del Paraná', 'San Rafael Del Paraná', 'Tomas Romero Pereira', 'Trinidad', 'Yatytay'],
            //     'Misiones': ['Ayolas', 'Escobar', 'San Ignacio', 'San Juan Nepomuceno', 'San Miguel', 'San Patricio', 'Santa María', 'Santa Rosa', 'Santiago', 'Villa Florida', 'Yabebyry'],
            //     'Paraguarí': ['Acahay', 'Carapeguá', 'Caapucú', 'Gral. Bernardino Caballero', 'La Colmena', 'Maria Antonia', 'Mbuyapey', 'Paraguarí', 'Pirayí', 'Quiindy', 'Quyquyhó', 'San Roque G. De S. Cruz', 'Sapucai', 'Tebicuarymi', 'Yaguarón', 'YbycuÍ', 'Ybytimi'],
            //     'Alto Paraná': ['Ciudad Del Este', 'Domingo M. De Irala', 'Dr. Juan L. Mallorquín', 'Hernandarias', 'IruÑA', 'Itakyry', 'Juan E. Oleary', 'Los Cedrales', 'MbaracayÚ', 'Minga Guazú', 'Minga Porá', 'Naranjal', 'Ñacunday', 'Pte. Franco', 'Raúl Peña', 'San Alberto', 'San Cristóbal', 'Santa Fé Del Paraná', 'Santa Rita', 'Santa Rosa Del Monday', 'Tavapy', 'Yguazú'],
            //     'Central': ['Areguá', 'Capiatá', 'Fernando De La Mora', 'Guarambaré', 'Itá', 'Itauguá', 'J. Augusto Saldívar', 'Lambaré', 'Limpio', 'Luque', 'Mariano R. Alonso', 'Nueva Italia', 'Ñemby', 'San Antonio', 'San Lorenzo', 'Villa Elisa', 'Villeta', 'Ypacaraí', 'Ypané'],
            //     'Ñeembucú': ['Alberdi', 'Cerrito', 'Desmochados', 'Gral. José Eduvigis Díaz', 'Guazucuá', 'Humaitá', 'Isla Umbú', 'Laureles', 'Mayor J. Dejesús Martínez', 'Paso De Patria', 'Pilar', 'San Juan B. De ÑEembucú', 'San Juan Bautista', 'Tacuaras', 'Villa Franca', 'Villa Oliva', 'Villalbín'],
            //     'Amambay': ['Bella Vista Norte', 'Capitán Bado', 'Caraguatay', 'Cerro Cora', 'Gral. Francisco Caballero', 'Karapai', 'Pedro Juan Caballero', 'Zanja Pytậ'],
            //     'Canindeyú': ['Corpus Christi', 'Itanará', 'Katueté', 'La Paloma', 'Laurel', 'Maracana', 'Nueva Esperanza', 'Puerto Adela', 'Salto Del Guairá', 'Yasy Kañy', 'Yby Pyta ', 'Ybyrarobana', 'Ygatimí', 'Ypejhu'],
            //     'Presidente Hayes': ['BenjamÍN Aceval', 'Campo Aceval', 'Gral. José Maria Bruguez', 'Nanawa', 'Nueva Asuncion', 'Puerto Falcón', 'Puerto Pinasco', 'Teniente 1° Manuel Irala Fernandez', 'Teniente Esteban Martinez', 'Villa Hayes'],
            //     'Alto Paraguay': ['Bahia Negra', 'Boqueron', 'Carmelo Peralta', 'Fuerte Olimpo'],
            //     'Boquerón': ['Filadelfia', 'La Victoria', 'Loma Plata', 'Mcal José Felix Estigarribia']
            // },
            // as Record<string, string[]>,
            arrayDenunciante: [],
            arrayDenunciado: [],
            arrayFile: [],
            carga_denunciados: true,
            carga_denunciantes: false,

            denuncia: {
                uid: "",
                comprobante: "",
                tipoDenuncia: "rendiciones",
                denunciaAnonima: true,
                denunciaOtraInstitucion: false,
                otraInstitucion: "",
                fechaDeLosHechos: "",
                institucionDenunciada: "",
                otraInstitucionDenunciada: null,
                ciudad: "",
                departamento: "",
                descripcion: "",
                urlDocumentoAdjunto: "",
                pretension: "",
                estado: null,
                administradoPorUsuario: null,
                fechaCreacion: new Date(),
                denunciados: [],
                denunciantes: [],
                archivos: [],
                base64data: "",
            },
            denunciados: {
                nombreCompleto: "",
                tipoDocumento: "",
                nroDocumento: "",
                esFuncionario: false,
                esEliminado: false,
                denunciaUid: null,
            },
            denunciantes: {
                nombreCompleto: "",
                tipoDocumento: "",
                nroDocumento: "",
                email: "",
                telefono: "",
                protegerDatos: true,
                esEliminado: false,
                denunciaUid: null,
            },
            //conf. pdf
            pdfOptions: {
                margin: { top: 10, right: 10, bottom: 10, left: 10 },
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "mm", format: "a4", orientation: "p" },
            },
            blobArchive: "",
            iFrame: "",
            exportFilename: "my-custom-file.pdf",
            base64data: "",
            mostrarExp: true,
            loading: false,
            cantArchivo: 0,
            asunto: '',
            expediente: '',
            nombreAsunto: '',
            denunciante: '',
        };
    },

    metaInfo() {
        return {
            title: "Hace tu denuncia",
        };
    },

    methods: {
        eliminarDenunciado(index) {
            this.arrayDenunciado.splice(index, 1);
        },

        eliminarAdjunto(index) {
            this.arrayFile.splice(index, 1);
        },

        eliminarDenunciante(index) {
            this.arrayDenunciante.splice(index, 1);
        },

        refresh() {
            this.arrayDenunciado = [];
            this.arrayDenunciante = [];

            this.$router.replace("/estado-denuncia");
        },

        fileType(tipo) {
            if (tipo) {
                const extension = tipo.split(".").pop()?.toLowerCase();
                return extension;
            }
            return "No hay archivo seleccionado";
        },

        async btnFileSelector() {
            this.$refs.fileSelector.click();
        },

        onFileChange(event) {
            if (this.cantArchivo > 3) {
                Swal.fire({
                    title: "Error al subir el archivo",
                    text: `No se puede subir más de 4 archivos por denuncia.`,
                    icon: "error",
                    confirmButtonColor: "#356AA0",
                });
                return;
            }
            const file = event.target.files[0];
            if (file && file.size > this.maximoPermitido) {
                Swal.fire({
                    title: "Error al subir el archivo",
                    text: `El archivo seleccionado es demasiado grande. El tamaño máximo permitido es de ${ this.maximoPermitido / 5000000 } MB.`,
                    icon: "error",
                    confirmButtonColor: "#356AA0",
                });
                event.target.value = null; // Limpia el valor del componente de entrada de archivo
                this.selectArchivo = null; // Limpia el archivo seleccionado en el modelo de datos
            } else {
                this.cantArchivo = this.cantArchivo + 1;
                this.selectArchivo = file;
            }
            this.nuevoArchivo();
        },

        async convertirArchivoABase64(file) {
            // console.log('METODO CONVERTTOBASE64 EL FILE ES: ', file);
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64doc = reader.result.split(',')[1];
                    resolve(base64doc);
                };

                reader.onerror = (error) => {
                    reject(error);
                }

                // if (file.type.includes('video/mp4') || file.name.endsWith('.mp4')) {
                //     reader.readAsDataURL(file);
                // } else if (file.type.includes('image/png') || file.type.includes('image/jpeg') || file.name.endsWith('.png') || file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')) {
                //     reader.readAsDataURL(file);
                // } else {
                //     // Maneja otros tipos de archivos según sea necesario
                //     reject(new Error("Tipo de archivo no compatible."));
                // }

                reader.readAsDataURL(file);
            });
        },
        
        //crear archivo y enviar peticionSOAP
        async convertirYEnviar() {
            this.loading = true;
            try {
                const base64data = await this.download();
                await this.enviarSOAP(base64data);
            } catch (error) {
                // console.error("Error en la operación completa:", error);
                this.loading = false;
            }
        },
        
        // decargar pdf base 64
        async download() {
            try {
                // console.log("descargando!!");
                const el = document.getElementById("vue3SimpleHtml2pdf"); //ojo con la ref y el byId
                if (!el) {
                    return;
                }
                //habilitar bajar el archivo
                // html2pdf().from(el).set(this.pdfOptions).save(this.exportFilename);
                // this.iFrame = await html2pdf().from(el).output('bloburl');

                const blobArchive = await html2pdf().from(el).output("blob");
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    reader.onload = function () {
                        const base64data = reader.result.split(",")[1];
                        // console.log("PDF en base64:", base64data);
                        resolve(base64data);
                    };
                    reader.onerror = function (error) {
                        reject(error);
                    };
                    reader.readAsDataURL(blobArchive);
                    // console.log(this.blobArchive);
                });
            } catch (error) {
                console.error("Error durante la conversión a base64:", error);
                throw error;
            }
        },

        async enviarSOAP(base64data) {
            try {
                if(this.denuncia.denunciaAnonima == true) {
                    this.asunto = "Denuncia Anónima Vía Portal";
                    this.expediente = 108; // Anonima
                    this.denunciante = '';
                } else if (this.denuncia.denunciaAnonima == false) {
                    this.asunto = "Denuncia Nominal Vía Portal";
                    this.expediente = 110; //Nominal
                    this.nombreAsunto = '';
                    this.denunciante = this.arrayDenunciante[0].nombreCompleto + 'y/o otros';
                }

                let varSolicitudSOAP = `
                    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="http://www.apiasolutions.com/WebServices">
                        <soapenv:Header>
                            <web:SOAPAction>ws_generacion_expediente_extendido</web:SOAPAction>
                        </soapenv:Header>
                        <soapenv:Body>
                            <web:ws_generacion_expediente_extendido>
                                <web:ApiaWSInput>                                                                                       
                                    <web:B_PRMT_OFICINA_ORIGEN_EXPEDIENTE>31</web:B_PRMT_OFICINA_ORIGEN_EXPEDIENTE>
                                    <web:B_PRMT_TIPO_EXPEDIENTE>` + this.expediente + `</web:B_PRMT_TIPO_EXPEDIENTE>
                                    <web:B_PRMT_ASUNTO_EXPEDIENTE>` + this.asunto + `/` + this.denunciante + `</web:B_PRMT_ASUNTO_EXPEDIENTE>
                                    <web:B_PRMT_PRIORIDAD_EXPEDIENTE>1</web:B_PRMT_PRIORIDAD_EXPEDIENTE>
                                    <web:B_PRMT_CONFIDENCIALIDAD_EXPEDIENTE>1</web:B_PRMT_CONFIDENCIALIDAD_EXPEDIENTE>
                                    <web:B_PRMT_DOCUMENTACION_FISICA_EXPEDIENTE>1</web:B_PRMT_DOCUMENTACION_FISICA_EXPEDIENTE>
                                    <web:B_PRMT_CLASIFICACION_EXPEDIENTE>1</web:B_PRMT_CLASIFICACION_EXPEDIENTE>`;
                                
                                varSolicitudSOAP +=
                                    `<web:B_archivoAdjunto>` + base64data + `</web:B_archivoAdjunto>`;

                
                if (this.arrayFile.length > 0) {
                    // console.log('Entre en el IF, por ende hay un archivo adjunto o más');
                    // console.log('Este es el arrayFile: ', this.arrayFile);
                    // console.log('Este es el arrayFile Length: ', this.arrayFile.length);
                    for (let i = 0; i < this.arrayFile.length; i++) {
                        let pos = [i];
                        // console.log('Posicion para usar: ', pos);
                        // console.log('Entro en el FOR: ', i);

                        const archivo = this.arrayFile[i].selectArchivo;
                        const base64doc = await this.convertirArchivoABase64(archivo);
                        // console.log('Este es el archivo elegido: ', archivo);
                        // console.log('Este es el archivo convertido: ', base64doc);

                        if(pos == 0) {
                            // console.log('Este tiene que ser el dos');
                            varSolicitudSOAP +=
                                    `<web:B_archivoAdjuntoDos>` + base64doc + `</web:B_archivoAdjuntoDos>`;
                        } else if(pos == 1) {
                            // console.log('Este tiene que ser el tres');
                            varSolicitudSOAP +=
                            `<web:B_archivoAdjuntoTres>` + base64doc + `</web:B_archivoAdjuntoTres>`;
                        } else if(pos == 2) {
                            // console.log('Este tiene que ser el cuatro');
                            varSolicitudSOAP +=
                            `<web:B_archivoAdjuntoCuatro>` + base64doc + `</web:B_archivoAdjuntoCuatro>`;
                        } else if(pos == 3) {
                            // console.log('Este tiene que ser el cinco');
                            varSolicitudSOAP +=
                            `<web:B_archivoAdjuntoCinco>` + base64doc + `</web:B_archivoAdjuntoCinco>`;
                        }

                    }

                    varSolicitudSOAP += `</web:ApiaWSInput>
                            </web:ws_generacion_expediente_extendido>
                        </soapenv:Body>
                    </soapenv:Envelope>`;

                } else {
                    varSolicitudSOAP += `</web:ApiaWSInput>
                                </web:ws_generacion_expediente_extendido>
                            </soapenv:Body>
                        </soapenv:Envelope>`;
                }
                const solicitudSOAP = varSolicitudSOAP;

                // console.log('Resultado SOAP: ', solicitudSOAP);

                // const response = await api.post(
                const response = await axios.post(
                    "ApiaWSws_generacion_expediente_extendido",
                    // "http://10.168.100.81:8080/Apia/services/ApiaWSws_generacion_expediente_extendido",
                    solicitudSOAP,
                    {
                        baseURL: "/", // Establece la URL base del servidor proxy
                        headers: {
                            "Content-Type": "text/xml",
                            SOAPAction: "ws_generacion_expediente_extendido",
                        },
                    }
                );
                // console.log(response.data);
                const respuestaXML = response.data;
                xml2js.parseString(respuestaXML, (err, result) => {
                    if (err) {
                        // console.error('Error al analizar XML:', err);
                        return;
                    }
                    // const expedienteStr = result["soapenv:Envelope"]["soapenv:Body"][0]["ws_generacion_expediente_extendido_response"][0]["ws_generacion_expediente_extendido_result"][0];
                    const expedienteStr = result["soapenv:Envelope"]["soapenv:Body"][0]["ws_generacion_expediente_extendidoResponse"][0]["ExecResult"][0]['parameters'][0]['PRMT_NRO_EXPEDIENTE_STR'][0];
                    this.resultado = expedienteStr;
                    // console.log('El expediente es: ', this.resultado);
                    this.loading = false;

                    Swal.fire({
                        title: "¡Tu denuncia fue enviada de forma correcta!",
                        html: `<p style="font-size: 15px;">Recuerda guardar el código único generado para tu denuncia con el fin de poder realizar seguimiento efectivo de la misma.</p>
                                <p style="color: red; font-size: 15px;">Si olvidas este número no podrás ver el proceso ni los resultados de tu denuncia, te recomendamos descargar un archivo con el número de comprobante.</p>
                                <h3>${ this.resultado }</h3>`,
                        icon: "success",
                        confirmButtonColor: "#356AA0",
                    });
                    
                    this.limpiarDenuncia();
                    this.limpiarDenunciantes();
                    this.limpiarDenunciados();


                });

            } catch (error) {
                // console.error("Error en downloadAndAnotherMethod:", error);
                this.loading = false;
                // Maneja el error de acuerdo a tus necesidades
            }
        },

        cerrarModal() {
            // const myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'));
            // myModal.hide();
        },

        isRequired(value) {
            if (!value) {
                // console.log("Este campo es requerido");
                return "Este campo es requerido";
            }
            return true;
        },

        validateEmail(value) {
            if (!value) {
                return "Este campo es requerido";
            }

            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return "Este campo debe ser un correo";
            }
            return true;
        },

        // async crear() {
        crear() {
            // let that = this;
            console.log('Array Denunciado: ', this.arrayDenunciado);
            console.log('Array Denunciante: ', this.arrayDenunciante);
            this.denuncia.denunciados = this.arrayDenunciado;
            this.denuncia.denunciantes = this.arrayDenunciante;
            console.log('hola mundo');
            console.log('Array Denunciante: ' + this.denuncia.denunciantes);
            this.denuncia.archivos = this.arrayFile;
            
            // console.log(
                // "estos son los datos de la denuncia -----> " +
                // this.denuncia.tipoDenuncia
            // );
            console.log('Estos son los datos de la denuncia; ' + this.denuncia);
            Object.entries(this.denuncia).forEach(([key, value]) => {
                console.log(`${key}: ${value}`);

                if (Array.isArray(value)) {
                    // console.log("denunciados es un array.");
                    Object.entries(value).forEach(([key, value]) => {
                        console.log(`${key}: ${value.nombreCompleto}`);
                    });
                }
            });
            // for (const key in this.denuncia) {
            //   if (Object.prototype.hasOwnProperty.call(this.denuncia, key)) {
            //     console.log(`${key}: ${this.denuncia[key]}`);
            //   }
            // }
            // this.archivos = this.arrayFile

            // this.carga_comprobante = false;

            // this.denuncia_response = await this.crearDenuncia(this.denuncia).then(resp => resp)

            // // this.denuncia_response = { comprobante: '6B105498', uid: '9bc60ab3-cd9f-4f27-b157-0258a322e476' }

            // let uid = this.denuncia_response.uid

            // Promise.all(this.archivos.map((archivo) => {
            //   return this.uploadFile(archivo.selectArchivo, uid);
            // })).then((fileUploaded) => {
            //   console.log(fileUploaded);
            // });

            // this.carga_comprobante = true;
        },

        uploadFile(archivo, uid) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                const binary = reader.readAsDataURL(archivo);
                console.log(binary.toString());

                const formData = new FormData();
                formData.append("file", archivo, archivo.name);
                let headers = {
                    "Content-Type": "multipart/form-data",
                    accept: "*/*",
                };

                const fileData = { uid: uid, formData: formData, headers: headers };
                resolve(this.subirArchivo(fileData));
                console.log(reject);
            });
        },

        actualizarMun() {
            this.denuncia.ciudad = "";
        },

        seleccionOtraOEE({ id, text }) {
            console.log("metodo seleccionOtraOEE --->" + id + text);
        },

        guardarCodigo() {
            window.print();
        },

        copiarCodigo() {
            this.copiado = false;
            navigator.clipboard.writeText(
                (this.copiar_comprobante = this.denuncia_response.comprobante)
            );
            this.copiado = true;
        },

        funcionarioBoolean(event) {
            if (event.target.value === "si") {
                this.denunciados.esFuncionario = true;
            } else if (event.target.value === "no") {
                this.denunciados.esFuncionario = false;
            }
        },

        imprimir() {},

        botonDenuncia() {
            if (
                this.denunciantes.nombreCompleto == "" &&
                this.denunciantes.tipoDocumento == "" &&
                this.denunciantes.nroDocumento == "" &&
                this.denunciantes.email == "" &&
                this.denunciantes.telefono == "" &&
                this.denunciados.nombreCompleto == "" &&
                this.denunciados.tipoDocumento == "" &&
                this.denunciados.nroDocumento == "" &&
                this.funcionario == "" &&
                this.denuncia.otraInstitucion == "" &&
                this.denuncia.fechaDeLosHechos == "" &&
                this.denuncia.institucionDenunciada == "" &&
                this.denuncia.ciudad == "" &&
                this.denuncia.departamento == "" &&
                this.denuncia.descripcion == "" &&
                this.denuncia.urlDocumentoAdjunto == "" &&
                this.denuncia.pretension == ""
            ) {
                this.vacio = false;
            } else {
                this.vacio = true;
            }
        },

        limpiarDenunciantes() {
            (this.denunciantes.nombreCompleto = ""),
            (this.denunciantes.tipoDocumento = ""),
            (this.denunciantes.nroDocumento = ""),
            (this.denunciantes.email = ""),
            (this.denunciantes.telefono = "");
        },

        limpiarDenunciados() {
            (this.denunciados.nombreCompleto = ""),
            (this.denunciados.tipoDocumento = ""),
            (this.denunciados.nroDocumento = ""),
            (this.funcionario = "");
        },

        limpiarDenuncia() {
            this.denuncia.uid = "";
            this.denuncia.comprobante = "";
            this.denuncia.tipoDenuncia = "rendiciones";
            this.denuncia.denunciaAnonima = true;
            this.denuncia.denunciaOtraInstitucion =  false;
            this.denuncia.otraInstitucion = "";
            this.denuncia.fechaDeLosHechos = "";
            this.denuncia.institucionDenunciada = "";
            this.denuncia.otraInstitucionDenunciada = null;
            this.denuncia.ciudad = "";
            this.denuncia.departamento = "";
            this.denuncia.descripcion = "";
            this.denuncia.urlDocumentoAdjunto = "";
            this.denuncia.pretension = "";
            this.denuncia.estado = null;
            this.denuncia.administradoPorUsuario = null;
            this.denuncia.fechaCreacion = new Date();
            this.denuncia.denunciados = [];
            this.denuncia.denunciantes = [];
            this.denuncia.archivos = [];
            this.denuncia.base64data = "";
            this.arrayDenunciado = [];
            this.arrayDenunciante = [];
            this.arrayFile = [];
        },

        // ...mapMutations('apiArchivos', ['nuevoArchivo']),

        // ...mapActions('apiDenuncias', ['crearDenuncia', 'obtenerInstituciones']),

        // ...mapActions('apiArchivos', ['subirArchivo']),

        activeType(active) {
          //console.log("elegí: " + active);
            if (active == "rendiciones") {
                (this.denuncia.tipoDenuncia = "Rendiciones de cuentas en concepto de FONACIDE y Royalties"),
                (this.active_rendiciones = true);
                (this.active_presentacion = false),
                (this.active_licitaciones = false),
                (this.active_manejo = false),
                (this.active_funcionarios = false),
                (this.active_otros = false);
            } else if (active == "presentacion") {
                (this.denuncia.tipoDenuncia = "Presentación o no de las Declaraciones Juradas"),
                (this.active_presentacion = true);
                (this.active_rendiciones = false),
                (this.active_licitaciones = false),
                (this.active_manejo = false),
                (this.active_funcionarios = false),
                (this.active_otros = false);
            } else if (active == "licitaciones") {
                (this.denuncia.tipoDenuncia = "Licitaciones Públicas"),
                (this.active_licitaciones = true);
                (this.active_rendiciones = false),
                (this.active_presentacion = false),
                (this.active_manejo = false),
                (this.active_funcionarios = false),
                (this.active_otros = false);
            } else if (active == "manejo") {
                (this.denuncia.tipoDenuncia = "Manejo indebido del Bien Público"),
                (this.active_manejo = true);
                (this.active_rendiciones = false),
                (this.active_presentacion = false),
                (this.active_licitaciones = false),
                (this.active_funcionarios = false),
                (this.active_otros = false);
            } else if (active == "funcionarios") {
                (this.denuncia.tipoDenuncia = "Contra Funcionarios de la CGR"),
                (this.active_manejo = false);
                (this.active_rendiciones = false),
                (this.active_presentacion = false),
                (this.active_licitaciones = false),
                (this.active_funcionarios = true),
                (this.active_otros = false);
            } else if (active == "otros") {
                (this.denuncia.tipoDenuncia = "Otros"), (this.active_otros = true);
                (this.active_rendiciones = false),
                (this.active_presentacion = false),
                (this.active_licitaciones = false),
                (this.active_funcionarios = false),
                (this.active_manejo = false);
            }
        },

        tooggleShowForm(anonymous) {
            // console.log("metodo: tooggleShowForm " + " variable: " + anonymous);
            if (true == anonymous) {
                this.showForm = false;
                this.denuncia.denunciaAnonima = true;
            }
            if (false == anonymous) {
                this.showForm = true;
                this.denuncia.denunciaAnonima = false;
            }
        },

        activeChange(act) {
            if (true == act) {
                this.denunciantes.protegerDatos = true;
            }
            if (false == act) {
                this.denunciantes.protegerDatos = false;
            }
        },

        activeChange_other(act) {
            // console.log("metodo: activeChange_other " + " variable: " + act);
            if (true == act) {
                this.denuncia.denunciaOtraInstitucion = true;
            }
            if (false == act) {
                this.denuncia.denunciaOtraInstitucion = false;
            }
        },

        checkLength() {
            if (this.denuncia.descripcion.length > this.maxLength) {
                this.denuncia.descripcion = this.denuncia.descripcion.slice(0, this.maxLength);
            }
        },
        checkLengthTwo() {
            if (this.denuncia.pretension.length > this.maxLength) {
                this.denuncia.pretension = this.denuncia.pretension.slice(0, this.maxLength);
            }
        },

        nuevoDenunciado() {
            // console.log("metodo:nuevoDenunciado!! ");
            if (this.carga_denunciados) {
                // console.log("elimina");
                this.arrayDenunciado.shift();
            }

            if (
                !(
                    this.denunciados.nombreCompleto == "" &&
                    this.denunciados.tipoDocumento == "" &&
                    this.denunciados.nroDocumento == ""
                )
            ) {
                if (!(this.denunciados.nombreCompleto.trim() == "")) {
                    this.arrayDenunciado.push({
                        nombreCompleto: this.denunciados.nombreCompleto,
                        tipoDocumento: this.denunciados.tipoDocumento,
                        nroDocumento: this.denunciados.nroDocumento,
                        esFuncionario: this.denunciados.esFuncionario,
                        esEliminado: this.denunciados.esEliminado,
                        denunciaUid: this.denunciados.denunciaUid,
                    });
                }

                this.carga_denunciados = false;
            }
        },
        nuevoDenunciante() {
            // console.log("metodo:nuevoDenunciante ");
            if (this.carga_denunciantes) {
                this.arrayDenunciante.shift();
            }

            if (
                !(
                    this.denunciantes.nombreCompleto == "" ||
                    this.denunciantes.tipoDocumento == "" ||
                    this.denunciantes.nroDocumento == "" ||
                    this.denunciantes.email == "" ||
                    this.denunciantes.telefono == ""
                )
            ) {
                this.arrayDenunciante.push({
                    nombreCompleto: this.denunciantes.nombreCompleto,
                    tipoDocumento: this.denunciantes.tipoDocumento,
                    nroDocumento: this.denunciantes.nroDocumento,
                    email: this.denunciantes.email,
                    telefono: this.denunciantes.telefono,
                    protegerDatos: this.denunciantes.protegerDatos,
                    esEliminado: this.denunciantes.esEliminado,
                    denunciaUid: this.denunciantes.denunciaUid,
                });
                this.carga_denunciantes = false;
                this.limpiarDenunciantes();
            } else {
                this.$swal({
                    title: "Mensaje",
                    html: "Todos los datos personales son <strong>requeridos</strong> !!",
                    icon: "warning",
                    confirmButtonColor: "#356AA0",
                });
            }
        },

        nuevoArchivo() {
            if (this.selectArchivo) {

                this.arrayFile.push({
                    selectArchivo: this.selectArchivo,
                    base64: this.base64,
                });
            }
        },

        setDepartamentos() {
            JSONService.getDataDepartamento().then((response) => {
                this.deptos = response[0].departamento.map((departamento) => {
                    return {
                        text: departamento.nombre,
                        id: departamento.nombre,
                    };
                });
            });
        },
        setMunicipios() {
            // Llamar al servicio JSON y obtener datos de municipios
            JSONService.getDataMunicipio().then((response) => {
                // Transformar la estructura de datos obtenida del servicio JSON
                this.mun = response[0].municipio;
                const numMunicipios = Object.keys(this.mun).length;
                console.log("Número de municipios:", numMunicipios);
                // Mostrar todos los datos de mun en la consola
                for (const municipio in this.mun) {
                    if (Object.prototype.hasOwnProperty.call(this.mun, municipio)) {
                        // const ciudades = this.mun[municipio].join(', ');
                        // console.log(`${municipio}: ${ciudades}`);
                    }
                }
            });
        },
        setOEE() {
            JSONService.getDataOEE().then((response) => {
                this.instituciones = response[0].institucion.map((institucion) => {
                    return {
                        text: institucion.nombre,
                        id: institucion.nombre,
                    };
                });
            });
        },
    },
    mounted() {
        this.setDepartamentos();
        this.setMunicipios();
        this.setOEE();
    },

    computed: {
        // ...mapState('apiDenuncias', ['entradas', 'entrada']),
        // ...mapState('apiArchivos', ['archivosArray']),

        remainingChars() {
            return this.maxLength - this.denuncia.descripcion.length;
        },

        remainingCharsTwo() {
            return this.maxLength - this.denuncia.pretension.length;
        },

        municipiosFiltrados() {
            // console.log(
                // "COMPUTED municipiosFiltrados: " + this.denuncia.departamento
            // );
            if (this.denuncia.departamento) {
                return this.mun[this.denuncia.departamento];
            } else {
                return [];
            }
        },

        nombreInstituciones() {
            return this.instituciones.map(
                //@ts-ignore
                (institucion) => institucion.nombre
            );
        },

        otrosSelected() {
            return this.denuncia.institucionDenunciada === "Otros";
        },
    },

    watch: {
        "denuncia.institucionDenunciada"(newV, preV) {
            if (newV !== "OTRA INSTITUCIÓN") {
                // console.log("se eligio uns institución!");
                this.denuncia.otraInstitucionDenunciada = null;
                this.isOtraInstitucion = false;
                console.log(preV);
            } else {
                this.isOtraInstitucion = true;
            }
        },
    },

    created() {
        // this.arrays()
        // this.obtenerInstituciones().then(res => {
        //   this.instituciones = res
        // })//.catch(error => console.log())
        // this.$store.dispatch('goToTop')
    },
};
</script>

<style>
.pasos-form-denuncias .active {
    padding: 20px;
    background: #356aa0;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    color: #fff;
}

.pasos-form-denuncias .inactive {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
}

.active-btn-denuncia-yes {
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 13px 70px;
    background-color: #356aa0;
    border-radius: 8px 0px 0px 8px;
    box-shadow: 0px 3px 6px #00000029;
}

.active-btn-denuncia-no {
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 13px 70px;   
    background-color: #356aa0;
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0px 3px 6px #00000029;
}

.btn-yes {
    border: none;
    font-size: 18px;
    color: #356aa0;
    font-weight: bold;
    padding: 13px 70px;
    background-color: #fff;
    border-radius: 8px 0px 0px 8px;
    box-shadow: 0px 3px 6px #00000029;
}

.btn-no {
    border: none;
    font-size: 18px;
    color: #356aa0;
    font-weight: bold;
    padding: 13px 70px;
    background-color: #fff;
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0px 3px 6px #00000029;
}

.color-text {
    color: #356aa0;
}

@media (max-width: 400px) {
    .btn-yes {
        padding: 13px 50px !important;
    }

    .btn-no {
        padding: 13px 50px !important;
    }

    .active-btn-denuncia-yes {
        padding: 13px 50px;
    }

    .active-btn-denuncia-no {
        padding: 13px 50px;
    }

    .readonly-input {
        border: none;
        background-color: transparent;
        outline: none;
    }
}

.loader {
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
</style>