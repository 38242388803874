<template>
    <section id="heroDenuncias" class="d-flex align-items-center">
        <img src="/assets/img/denuncias/img-inside-banner.png" alt="" style="padding-left: 15%;">
    </section>
</template>

<script>
export default {
    name: 'vBannerDenuncias'
}
</script>