<template>
    <section id="pf" class="frequent-questions py-5">
        <h2 class="text-center mb-5">Preguntas frecuentes</h2>
        <div class="container-accordion col-xl-8 col-md-9 col-10 m-auto">
            <ul class="accordion accordion-flush p-0" id="accordionFlushExample">
                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-one-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-one-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    ¿Qué se puede denunciar?
                                </div>
                            </button>
                        </h2>
                        <div id="question-one-collapse" class="accordion-collapse collapse" aria-labelledby="question-one-heading"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">Todo hecho que se considere irregular en el uso y manejo de los Bienes Públicos del Estado por parte de un Servidor Público, por ejemplo: en la presentación o no de la Declaración Jurada de Bienes y Rentas; Doble Remuneración del Servidor Público; en la presentación de las Rendiciones de Cuentas por parte de los Municipios y Gobernaciones, uso indebido de vehículos del Estado; irregularidad en los procesos de contratación; etc.</p>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-two-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-two-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    ¿Cómo realizo el seguimiento de mi denuncia?
                                </div>
                            </button>
                        </h2>
                        <div id="question-two-collapse" class="accordion-collapse collapse" aria-labelledby="question-two-heading"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">Tu denuncia contara con un Código designado, generado automáticamente, con el que podrás realizar el seguimiento pertinente. Es importante recordar que, la denuncia puede ser desestimada en el caso que la misma no cuente con los datos requeridos o necesarios establecidos de conformidad a la Resolución CGR N° 269/2022.</p>
                                <a class="m-0" href="https://denuncias.contraloria.gov.py/api/archivos/public/obtenerArchivo?fullPath=files/Documentos/Res._CGR_N_269-2022.pdf">Descargar Resolución CGR N° 269/2022</a>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="accordion accordion-flush mb-2" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="question-three-heading">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#question-three-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div class="align-items-center">
                                    ¿Qué datos debe aportar mi denuncia?
                                </div>
                            </button>
                        </h2>
                        <div id="question-three-collapse" class="accordion-collapse collapse"
                            aria-labelledby="question-three-heading" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p class="m-0">Recuerda que tu denuncia debe ser lo más precisa posible, con todos los datos que puedan ayudar a determinar la veracidad y comprobación de los hechos denunciados; asimismo de contar con documentos de respaldo no dudes en adjuntarlos (tomas fotográficas, videos, documentos, facturas, etc.).</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'vPreguntasFrecuentes',
}
</script>

<style>
.frequent-questions {
	background-color: #356aa0;
}
.frequent-questions h2 {
	color: #fff;
	font-weight: bold;
}
.frequent-questions li {
	list-style: none;
}
.frequent-questions .accordion .accordion-item {
	border-radius: 10px;
	border: 1px solid #dddfe1;
	background: #fff 0% 0% no-repeat padding-box;
}
.frequent-questions .accordion .accordion-item .accordion-button:focus {
	box-shadow: none !important;
}
.frequent-questions .accordion .accordion-item .accordion-button:not(.collapsed) {
	color: #000;
	box-shadow: none !important;
	background-color: #fff !important;
}
.frequent-questions .accordion button {
	padding: 12px 25px;
	border-radius: 10px !important;
}
</style>