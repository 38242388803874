<template>
    <v-header></v-header>
    <v-banner></v-banner>
    <v-servicio></v-servicio>
    <v-denunciar></v-denunciar>
    <vDenunciasCiudadanas></vDenunciasCiudadanas>
    <v-nosotros-portada></v-nosotros-portada>
    <v-transparencia></v-transparencia>
    <v-contacto></v-contacto>
    <v-footer></v-footer>
</template>

<script>
import vHeader from '@/components/vHeader.vue';
import vBanner from '@/components/home/vBanner.vue';
import vServicio from '@/components/home/vServicio.vue';
import vDenunciar from '@/components/home/vDenunciar.vue';
import vDenunciasCiudadanas from '@/components/home/vDenunciasCiudadanas.vue';
import vNosotrosPortada from '@/components/home/vNosotrosPortada.vue';
import vTransparencia from '@/components/home/vTransparencia.vue';
import vContacto from '@/components/home/vContacto.vue';
import vFooter from '@/components/vFooter.vue';

export default {
    name: 'vHome',
    components: {
        vHeader, vBanner, vServicio, vDenunciar, vDenunciasCiudadanas, vNosotrosPortada, vTransparencia, vContacto, vFooter
    }
}
</script>