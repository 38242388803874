<template>
    <header id="header" class="d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">
            <router-link to="/" class="logo"><img src="assets/img/logo/logo.png" alt=""></router-link>
            <nav id="navbar" class="navbar">
                <ul>
                    <li><router-link to="/" class="nav-link scrollto" :class="{ active: $route.path === '/' }">INICIO</router-link></li>
                    <li><router-link to="/denuncia" class="nav-link scrollto" :class="{ active: $route.path === '/denuncia' }">HACE TU DENUNCIA</router-link></li>
                    <li class="dropdown"><router-link to="nosotros" class="nav-link scrollto" :class="{ active: $route.path === '/nosotros' }"><span>NOSOTROS</span> <i class="bi bi-chevron-down"></i></router-link>
                        <ul>
                            <li><router-link to="/nosotros" @click="goToSection" id="quienes-somos">QUIÉNES SOMOS</router-link></li>
                            <li><router-link to="/nosotros" @click="goToSection" id="como-trabajamos">CÓMO TRABAJAMOS</router-link></li>
                            <li><router-link to="/nosotros" @click="goToSection" id="preguntas-frecuentes">PREGUNTAS FRECUENTES</router-link></li>                   
                        </ul>
                    </li>
                </ul>
                <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>
        </div>
    </header>
</template>

<script>
export default {
    name: 'vHeader',

    methods: {
        goToSection(e) {
            const section = e.target.id;
            console.log(section);
        }
    }
}
</script>