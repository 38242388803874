<template>
    <v-header></v-header>
    <v-bannerDenuncias></v-bannerDenuncias>
    <v-form-denuncias></v-form-denuncias>
    <v-footer></v-footer>
</template>

<script>
import vHeader from '@/components/vHeader.vue';
import vFooter from '@/components/vFooter.vue';
import vBannerDenuncias from '@/components/denuncias/vBannerDenuncias.vue';
import vFormDenuncias from '@/components/denuncias/vFormDenuncias.vue';

export default {
    name: 'vDenuncias',

    components: {
        vHeader,
        vFooter,
        vBannerDenuncias,
        vFormDenuncias
    }
}
</script>