import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import VueSweetalert2 from 'vue-sweetalert2';
import Select2 from 'vue3-select2-component';

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';

const app = createApp(App);
app.use(router);
app.use(Vue3SimpleHtml2pdf);
app.use(VueSweetalert2);
app.use(Select2);
app.mount('#app');

