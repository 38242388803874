<template>
    <section id="ct" class="how-work row m-auto justify-content-center pb-xl-5 py-3">
        <div class="text-center img-responsive-how-work p-0">
            <img src="@/assets/img/nosotros/img-como-trabajamos.webp" alt="">
        </div>
        <div class="col-xl-5 how-work-description p-0 my-5">
            <h2 class="text-center m-0 pt-4">¿Cómo trabajamos?</h2>
            <div class="p-4 pb-0 text-how-work">
                <p>La Contraloría General de la República a través de la Dirección General de Integridad Pública y Transparencia ofrece a la ciudadanía herramientas como las denuncias y acceso a la información pública, a fin de transparentar y controlar las actividades económicas y financieras del Estado. Tu denuncia es importante porque contribuye a ese control e impulsa a las instituciones públicas a cumplir con las disposiciones legales vigentes.</p>
            </div>
        </div>
        <div class="col-xl-6 general-management p-0">
            <img src="@/assets/img/nosotros/img-como-trabajamos.webp" alt="">
        </div>
    </section>
</template>

<script>
export default {
    name: 'vComoTrabajamos',
}
</script>

<style>
.how-work {
	width: 90%;
}
.how-work .img-responsive-how-work {
	display: none;
}
.how-work .how-work-description {
	z-index: 1;
    color: #fff;
	margin-right: -3.5%;
	border-radius: 8px;
	background-color: #356aa0;
	box-shadow: 0px 2px 48px #000 14;
}
.how-work .how-work-description .text-how-work {
	font-size: 17.5px;
}
.how-work .general-management img {
	width: 100%;
}
</style>